import React, { useEffect, useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import MUIDataTable from 'mui-datatables';
import { useConfirm } from 'material-ui-confirm';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid,
  Tooltip, IconButton, Icon, CircularProgress,
} from '@material-ui/core';
import Divider from '../../../../components/Divider';
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import InputAutoComplete from '../../../../components/Inputs/InputAutoComplete';
import InputText from '../../../../components/Inputs/InputText';
import InputDate from '../../../../components/Inputs/InputDate';
import { useNotification } from '../../../../Utils/hooks';
import { required } from '../../../../Utils/utils';
import {
  createTFGCourtTimespan, getTFGTitulations, uploadTFGCourtTimestamps,
} from '../../../../services/TFG/TFGService';
import {
  createTFGCourt, deleteTFGCourtTimestamp, getTFGDefenseDirectors,
  updateTFGCourt,
} from '../../../../services/TFG/TFGService';
import { dateInstanceToLocale } from '../../../../Utils/Date';
import { MUIDatatableLabels } from '../../../../Utils/Table.utils';

const TFGCourtDialog = ({ visible, editing, onHide, onSuccess, selectedTerm }) => {
  const [loadingInstructors, setLoadingInstructors] = useState(false);
  const [showTimespanForm, setShowTimespanForm] = useState(false);
  const [timespanDate, setTimespanDate] = useState(null);
  const [instructors, setInstructors] = useState([]);
  const [timespans, setTimespans] = useState([]);
  const [name, setName] = useState('');
  const [chairman, setChairman] = useState(null);
  const [secretary, setSecretary] = useState(null);
  const [vocal, setVocal] = useState(null);
  const [degrees, setDegrees] = useState([]);
  const [degreeId, setDegreeId] = useState(null);
  const fileUploadRef = useRef(null);
  const confirm = useConfirm();

  const notification = useNotification();
  const isEdit = !!editing;
  const service = isEdit ? updateTFGCourt : createTFGCourt;

  const sortedTimespans = useMemo(() => {
    // Sort timespans by date using the "start" property
    return timespans.sort((a, b) => {
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);
      return dateA - dateB;
    });
  }, [timespans])

  useEffect(() => {
    setChairman(editing?.chairman || null);
    setSecretary(editing?.secretary || null);
    setVocal(editing?.vocal || null);
    setName(editing?.name || '');
    setTimespans(editing?.timeSpans || []);
    setDegreeId(editing?.degreeId || null);
  }, [editing]);

  useEffect(() => {
    if (!visible) {
      setChairman(null);
      setSecretary(null);
      setVocal(null);
      setName('');
      setTimespans([]);
      setShowTimespanForm(false);
      setTimespanDate(null);
      setDegreeId(null);
    }
  }, [visible]);

  useEffect(() => {
    async function retrieveInstructors(term) {
      try {
        setLoadingInstructors(true);
        const result = await getTFGDefenseDirectors(term).then(
          res => res.map(item => ({ label: item.name, value: item.externalId }))
        );
        if (!result) throw new Error();
        setInstructors(result);
      } catch {
        // Notification
      } finally {
        setLoadingInstructors(false);
      }
    }

    if (selectedTerm) retrieveInstructors(selectedTerm);
  }, [selectedTerm]);

  useEffect(() => {
    async function retrieveDegrees(term) {
      try {
        const result = await getTFGTitulations(term).then(
          res => res.map(item => ({ value: item.externalId, label: item.name }))
        );
        if (!result) throw new Error();
        setDegrees(result);
      } catch {
        // Notification
      }
    }

    if (selectedTerm) retrieveDegrees(selectedTerm);
  }, [selectedTerm]);

  const onSubmit = async () => {
    try {
      setApplicationLoading(true);
      const result = await service({ id: editing?.id || undefined, chairman, vocal, secretary, name, degreeId, academicYearId: selectedTerm });
      onSuccess(isEdit, result);
      if (isEdit) {
        notification('success', 'El tribunal de defensa se ha modificado correctamente');
      } else {
        notification('success', 'El tribunal de defensa se ha creado correctamente');
      }
    } catch {
      notification('error', `Ha habido un error al ${isEdit ? 'actualizar' : 'crear'} el tribunal de defensa`);
    } finally {
      setApplicationLoading(false);
    }
  }

  const dialogTitle = `${editing ? 'Editar' : 'Crear'} tribunal de defensa`;

  const onDeleteClick = id => {
    if (!editing) return;

    confirm({
      title: 'Eliminar intervalo',
      description: 'Esta acción eliminará el intervalo seleccionado, ¿deseas continuar?',
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          await deleteTFGCourtTimestamp(editing.id, id);
          setTimespans(prev => prev.filter(item => item.id !== id));
          // _getInstances();
        } catch {
          notification('error', 'Ha habido un error al eliminar el intervalo seleccionado');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const onUploadClick = event => {
    event.preventDefault();

    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  }

  const onFilesChange = async event => {
    if (!editing) return;

    const files = event.target.files;

    if (files) {
      const file = files.item(0);
      if (file) {
        try {
          setApplicationLoading(true);
          const result = await uploadTFGCourtTimestamps(editing.id, file);
          if (!result) throw new Error();
          notification('success', 'Los periodos de disponibilidad se han creado correctamente');
          setTimespans(result.timeSpans);
        } catch {
          notification('error', 'Ha habido un error al subir o interpretar el CSV');
        } finally {
          setApplicationLoading(false);
        }
      }
    }
  }

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    textLabels: {
      ...MUIDatatableLabels,
      body: {
        ...MUIDatatableLabels.body,
        noMatch: 'No se han creado periodos de disponibilidad'
      }
    },
    print: false,
    download: false,
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'start',
      label: 'Fecha',
      options: {
        customBodyRender: date => dateInstanceToLocale(date),
        filterType: 'textField'
      }
    },
    {
      name: 'reserved',
      label: 'Disponibilidad',
      options: {
        customBodyRender: reserved => reserved ? 'Reservado' : 'Disponible',
        filter: false,
      }
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRenderLite: index => {
          const { id, reserved } = timespans[index];
          return (
            <div className="actions">
              <Tooltip title="Eliminar" aria-label="delete" disabled={reserved}>
                <IconButton aria-label="delete" onClick={() => onDeleteClick(id)} disabled={reserved}>
                  <Icon className="delete-icon">
                    delete
                  </Icon>
                </IconButton>
              </Tooltip>
            </div>
          )
        }
      }
    }
  ]

  const onHideTimespanForm = () => {
    setShowTimespanForm(false);
    setTimespanDate('');
  }

  const onAddTimespan = async () => {
    if (!editing || !timespanDate) return;

    try {
      setApplicationLoading(true);
      const result = await createTFGCourtTimespan(editing.id, timespanDate);
      if (!result) throw new Error();
      notification('success', 'El periodo de disponibilidad se ha creado correctamente');
      setTimespans(result.timeSpans);
      onHideTimespanForm();
    } catch {
      notification('error', 'Ha habido un error al crear el periodo de disponibilidad');
    } finally {
      setApplicationLoading(false);
    }
  }

  const TableClass = classNames('timespans-table', { empty: !timespans.length });

  return (
    <Dialog
      className="court-form-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <form>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputText
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                // options={instructors}
                label="Nombre del tribunal"
                // loading={loadingInstructors}
                rules={required}
              />
            </Grid>
            { loadingInstructors ? (
              <Grid item xs={12} className="spinner-container">
                <p>Recuperando instructores...</p>
                <CircularProgress size={24} />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <InputAutoComplete
                    name="chairman"
                    value={chairman}
                    onChange={setChairman}
                    options={instructors}
                    label="Presidente"
                    loading={loadingInstructors}
                    rules={required}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputAutoComplete
                    name="secretary"
                    value={secretary}
                    onChange={setSecretary}
                    options={instructors}
                    label="Secretario"
                    loading={loadingInstructors}
                    rules={required}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputAutoComplete
                    name="vocal"
                    value={vocal}
                    onChange={setVocal}
                    options={instructors}
                    label="Vocal"
                    loading={loadingInstructors}
                    // rules={required}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputAutoComplete
                    name="degreeId"
                    value={degreeId}
                    onChange={setDegreeId}
                    options={degrees}
                    label="Titulación asociada"
                    // loading={loadingInstructors}
                    // rules={required}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Divider
                title="Periodos"
                actions={
                  <>
                    <Tooltip title="Crear periodo" aria-label="create-timespan">
                      <IconButton
                        aria-label="create-timespan"
                        onClick={() => setShowTimespanForm(prev => !prev)}
                        disabled={!editing?.id}
                      >
                        <Icon className="add-icon">add</Icon>
                      </IconButton>
                    </Tooltip>
                    <input
                      type="file"
                      onChange={onFilesChange}
                      multiple={false}
                      style={{ display: "none" }}
                      ref={fileUploadRef}
                      accept=".csv"
                    />
                    <Tooltip title="Subir CSV" aria-label="upload-csv">
                      <IconButton
                        aria-label="upload-csv"
                        onClick={onUploadClick}
                        disabled={!editing?.id}
                      >
                        <Icon className="upload-icon">file_upload</Icon>
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            </Grid>
            { showTimespanForm && (
              <>
                <Grid item md={10} xs={12}>
                  <InputDate
                    name="timespan-start"
                    label="Fecha"
                    // helperText="Fecha a partir de la que los alumnos pueden solicitar instancias de TFG para esta convocatoria"
                    value={timespanDate}
                    onChange={setTimespanDate}
                    rules={required}
                  />
                </Grid>
                <Grid item md={2} xs={12} className="add-timespan-button-container">
                  <Button variant="contained" color="primary" onClick={onAddTimespan}>
                    Añadir
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              { editing ? (
                <MUIDataTable
                  className={TableClass}
                  title="Periodos de disponibilidad"
                  columns={columns}
                  options={options}
                  data={sortedTimespans}
                />
              ) : (
                <span>
                  Realiza un guardado de directores para poder configurar sus
                  periodos de disponibilidad
                </span>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default TFGCourtDialog
